<template>
    <div class="page--with-tabs page--dashboard">
        <app-tabs class="page__tabs page__tabs--top" v-model="tab" :tabs="tabs" />

        <router-view />
    </div>
</template>

<script>
import appTabs from '@/components/app-tabs'

export default {
    components: {
        appTabs,
    },
    data() {
        return{
            tabs: [
                { id: 'dashboard',  name: 'dashboard',  title: 'Dashboard' },
                { id: 'audit',      name: 'audit',      title: 'Audit'     },
            ]
        }
    },
    computed: {
        tab: {
            get() {
                const tab = this.tabs.find( tab => tab.name == this.$route.name )
                    || this.tabs[0]

                return tab
            },

            set(tab) {
                this.$router.push({ name: tab.name, replace: true })
            }
        }
    }
}
</script>

<style lang="scss">
</style>
